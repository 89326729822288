// eslint-disable-next-line
import '/public/fonts.css';
// eslint-disable-next-line
import '/public/nprogress.css';

import App from 'next/app';
import join from 'url-join';
import * as Sentry from '@sentry/browser';

import axios from '../api/axios';
import Layout from '../components/Layout';
import GlobalStyle from '../styles/GlobalStyle';
import SentryReport from '../components/SentryReport';
import { PageWrapper } from '../components/common/styledComponents';
import { RootProvider } from '../context';
import { SERVER_URL, WEBSOCKET_URL } from '../constants/localStorageFields';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

class QERP extends App {
  constructor(props) {
    super(props);
    this.state = { axiosIsReady: false, hasError: false };
  }

  async componentDidMount() {
    const { host } = window.location;
    const isProduction = host.startsWith('plan');

    axios.interceptors.request.use(config => {
      if (isProduction) {
        const domain = host.slice(5);
        const serverUrl = `https://${domain}`;
        const apiUrl = `${serverUrl}/api`;
        const websocketUrl = `wss://${domain}/websocket/1234/`;

        localStorage.setItem(SERVER_URL, serverUrl);
        localStorage.setItem(WEBSOCKET_URL, websocketUrl);

        config.url = join(apiUrl, config.url);
      }

      return config;
    });

    axios.interceptors.response.use(
      function handleResponse(response) {
        return response;
      },
      function handleError(e) {
        if (e && e.response && e.response.status === 401) {
          let serverUrl = process.env.REACT_APP_SERVER_URL;

          if (isProduction) {
            const domain = host.slice(5);
            serverUrl = `https://${domain}`;
          }

          document.location.replace(serverUrl);
        }

        return Promise.reject(e);
      }
    );

    this.setState({ axiosIsReady: true });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      const eventId = Sentry.captureException(error);

      this.setState({ eventId });
    });
  }

  render() {
    const { Component, pageProps } = this.props;
    const { hasError, axiosIsReady, eventId } = this.state;

    if (!axiosIsReady) return <div />;

    if (hasError)
      return (
        <>
          <GlobalStyle />
          <PageWrapper>
            <SentryReport eventId={eventId} />
          </PageWrapper>
        </>
      );

    return (
      <RootProvider>
        <GlobalStyle />
        <Layout>
          <PageWrapper>
            <Component {...pageProps} />
          </PageWrapper>
        </Layout>
      </RootProvider>
    );
  }
}

export default QERP;
