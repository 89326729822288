import { MIN_UNSCROLLED_WIDTH, DIMENSIONS } from '../constants/continuum';

export function getScrollGap(scale = 1) {
  let scrollGap = MIN_UNSCROLLED_WIDTH * scale;
  if (scrollGap > MIN_UNSCROLLED_WIDTH) scrollGap = MIN_UNSCROLLED_WIDTH;
  else if (scrollGap < 20) scrollGap = 20;
  return scrollGap;
}

export function getScrollLimit(scale = 1) {
  return getScrollGap(scale) + 1;
}

export function getScaledDimensions(scale = 1) {
  const { maxW, colW, stepW, cellW, minuteW } = DIMENSIONS;
  return {
    ...DIMENSIONS,
    maxW: maxW * scale,
    colW: colW * scale,
    stepW: stepW * scale,
    cellW: cellW * scale,
    minuteW: minuteW * scale,
    scrollLimit: getScrollLimit(scale),
  };
}
