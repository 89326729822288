export const defaultError = 'Oops, something went wrong';
export const minActivityDurationError =
  'Minimum activity duration is 15 minutes ';
export const maxActivityDurationError = 'Max activity duration is twelve hours';
export const longTitleError = 'Please, write short title';
export const employeesOrderError = 'Failed to update order';
export const activityTypesError = 'Failed to load activity types';
export const workOrdersListError = 'Failed to load work orders list';
export const pageNotFoundError = 'Page is not found';
export const websocketError = 'Live reload is not working';
export const workOrderLoadError = 'Failed to load work order data';
export const coflictResolvingError =
  'Oops! Some conflicts still exists. Probably, someone has updated database';
export const weekDaysErrorMsg = 'Please, choose at least one day';
export const fileLoadingWarnMsg =
  'Files are loading, please wait for the unload to finish.';
export const noRights = 'You have not permission for this action!';
