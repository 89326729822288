// LIMITATIONS
export const MAX_ACTIVITIES_NUMBER = 3000;
export const MIN_DURATION = 0.25;
export const MAX_DURATION = 12;
export const WORK_ORDER_DURATION = 4;
export const MIN_DURATION_MINUTES = MIN_DURATION * 60;
export const MAX_DURATION_MINUTES = MAX_DURATION * 60;

// TIME IN PIXELS
export const HOUR_WIDTH = 23.125;
export const STEP_WIDTH = HOUR_WIDTH / 4;
export const MINUTE_WIDTH = HOUR_WIDTH / 60;
export const DAY_WIDTH = HOUR_WIDTH * 24;
export const MAX_WIDTH = MAX_DURATION * HOUR_WIDTH;
export const MIN_WIDTH = MIN_DURATION * HOUR_WIDTH;
export const WORK_ORDER_WIDTH = WORK_ORDER_DURATION * 23.125;

// DIMENSIONS
export const ROW_HEIGHT = 60; // 46
export const BLOCK_HEIGHT = 54; // 40
export const DAY_BORDER_WIDTH = 10;
export const OFFSET_DAY_WIDTH = DAY_WIDTH + DAY_BORDER_WIDTH;
export const LEFT_PANEL_WIDTH = 224; // 224
export const HEADER_HEIGHT = 50;
export const NAVIGATION_HEIGHT = 69;
// Following const should be included to vertical plane shift
// But day title is descendant of calendar (it is wrong structure)
export const DAY_TITLE_HEIGHT = 55;
export const TOP_PANEL_HEIGHT = HEADER_HEIGHT + NAVIGATION_HEIGHT;
export const GRID_LINE_WIDTH = 1;
export const ROW_TOP_PADDING = (ROW_HEIGHT - BLOCK_HEIGHT) / 2 - 1;
export const SCROLL_SPEED = 480; // px/s min speed is 60
export const MIN_UNSCROLLED_WIDTH = 150;

export const DIMENSIONS = Object.freeze({
  maxW: MAX_WIDTH,
  colW: DAY_WIDTH,
  rowH: ROW_HEIGHT,
  stepW: STEP_WIDTH,
  cellW: HOUR_WIDTH,
  blockH: BLOCK_HEIGHT,
  minuteW: MINUTE_WIDTH,
  borderW: DAY_BORDER_WIDTH,
  topPadding: ROW_TOP_PADDING,
  workOrderW: WORK_ORDER_WIDTH,
  planeShift: { x: LEFT_PANEL_WIDTH, y: TOP_PANEL_HEIGHT },

  scrollSpeed: SCROLL_SPEED,
  scrollLimit: MIN_UNSCROLLED_WIDTH,
});

// ZOOM
export const SCALING_SPEED = 1.1;
export const MAX_SCALE = 2;
export const MIN_SCALE = 0.5;
