import styled from 'styled-components';

import Header from './Header';
import { xl } from '../constants/stylesConstants';

export default ({ children }) => (
  <Layout>
    <Header />
    {children}
  </Layout>
);

const Layout = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;

  @media screen and (min-width: ${xl}) {
    max-width: 5140px;
  }
`;
