import styled, { css } from 'styled-components';
import { headerHeight, text, primary } from '../../constants/stylesConstants';

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const inheritSize = css`
  width: 100%;
  height: 100%;
`;

export const CenterFlex = styled.div`
  ${flexCenter}
  ${inheritSize}
`;

export const CenterFlexCol = styled.div`
  ${flexCenter}
  ${inheritSize}
  flex-direction: column;
`;

export const FlexBeetwen = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MarginRightLeft = styled.div`
  margin-right: 15px;
  margin-left: 15px;
`;

export const MarginRight = styled.div`
  margin-right: 15px;
`;

export const PageWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - ${headerHeight}px);
`;

export const SwitchWrapper = styled.div`
  ${flexCenter};
  margin-bottom: 10px;

  & > span:first-of-type {
    margin-right: 10px;
  }
  & > span:last-of-type {
    margin-left: 10px;
  }
`;

export const TwoBtnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

export const FormSectionTitle = styled.h4`
  font-size: 16px;
  color: ${text};
  margin-bottom: 5px;
  &::first-letter {
    text-transform: capitalize;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  color: ${text};
  margin-bottom: 5px;
`;

export const Title = styled.h1`
  font-size: 100px;
  color: ${primary};
  text-align: center;
  margin-bottom: 40px;
`;

export const Msg = styled.p`
  color: ${text};
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
`;
