import { SERVER_URL, WEBSOCKET_URL } from '../constants/localStorageFields';

const getLocationUrl = () => {
  const localStorageUrl = localStorage.getItem(SERVER_URL);

  if (localStorageUrl === null && process.env.REACT_APP_SERVER_URL !== null) {
    return process.env.REACT_APP_SERVER_URL;
  }

  if (localStorageUrl === null || localStorageUrl === undefined) {
    const { origin } = window.location;
    const re = 'plan.';
    const newUrl = origin.replace(re, '');
    return newUrl;
  }

  return localStorageUrl;
};

export function getServerUrl() {
  return typeof localStorage !== 'undefined'
    ? getLocationUrl()
    : process.env.REACT_APP_SERVER_URL;
}

export function getWebsocketUrl() {
  const local =
    typeof localStorage !== 'undefined' && localStorage.getItem(WEBSOCKET_URL);
  return local || process.env.REACT_APP_WEBSOCKET_URL;
}
