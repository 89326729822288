import { isQERP } from '../utils/hooks';

export default function Logo() {
  const isQerpInstance = isQERP(window.location.host);

  return (
    <img
      src={isQerpInstance ? '/icons/team.svg' : '/images/Tiat.svg'}
      alt="Tiat"
      style={{ display: 'block', height: '60%' }}
    />
  );
}
