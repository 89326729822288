export const areRectsIntersecting = (a, b) => {
  if (a.bottom < b.top || b.bottom < a.top) return false;
  // Coordinates are not calculated perfectly
  // That's why the half of pixel is ignored
  if (a.right - 0.6 < b.left || b.right - 0.6 < a.left) return false;
  return true;
};

export const resetStyles = ({ style }, { left, top, width }) => {
  style.width = width;
  style.left = left;
  style.top = top;
};

const findConflict = (element, collection) => {
  // This check is needed during first render in dev mode
  if (!element) return false;
  const box = element.getBoundingClientRect();
  return [].some.call(collection, nearby => {
    if (nearby.id === element.id) return false;
    return areRectsIntersecting(box, nearby.getBoundingClientRect());
  });
};

export const isSectorClear = (self, employeeId) => {
  const collection = document.getElementsByClassName(`employee-${employeeId}`);
  return !findConflict(self, collection);
};

export const updateRowConflicts = employeeId => {
  const collection = document.getElementsByClassName(`employee-${employeeId}`);
  [].forEach.call(collection, element => {
    const isConflict = findConflict(element, collection);
    if (!isConflict) element.classList.remove('conflict');
  });
};

export const handleOverlap = (self, initStyles) => {
  self.classList.add('overlap');
  if (initStyles) {
    setTimeout(() => {
      resetStyles(self, initStyles);
      self.classList.remove('overlap');
    }, 200);
  }
};

export const isActivityInRange = ({ startPoint, endPoint }, dates) => {
  const activityStart = new Date(startPoint.split(' ')[0]).getTime();
  const activityEnd = new Date(endPoint.split(' ')[0]).getTime();
  const rangeStart = new Date(dates[0].date).getTime();
  const rangeEnd = new Date(dates[dates.length - 1].date).getTime();

  return !(rangeStart > activityStart || rangeEnd < activityEnd);
};
