import { notification } from 'antd';
import SmileIcon from '../components/common/Icon';
import { defaultError } from '../constants/errorsMsgs';

export const showErrorNotification = msg => {
  notification.error({
    message: msg || defaultError,
    icon: SmileIcon,
  });
};

export const showWarnNotification = msg => {
  notification.warning({
    message: msg,
  });
};

export const showSuccessNotification = msg => {
  notification.success({
    message: msg,
  });
};

export const handleServerError = (e, callback) => {
  const msg =
    (e.response && e.response.data && e.response.data.error) || defaultError;
  if (callback) callback(msg);
  else showErrorNotification(msg);
};
