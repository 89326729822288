import React from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from 'antd';
import { CenterFlexCol, Title, Msg } from './common/styledComponents';

export default ({ eventId }) => (
  <CenterFlexCol>
    <Title>Oops...</Title>
    <Msg>It seems like something went wrong...</Msg>
    <Msg>
      We would really appreciate your feedback, so we can keep improving QERP
      Planning
    </Msg>
    <Button type="primary" onClick={() => Sentry.showReportDialog({ eventId })}>
      Report feedback
    </Button>
  </CenterFlexCol>
);
