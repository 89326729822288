import { ROW_HEIGHT, HOUR_WIDTH, GRID_LINE_WIDTH } from './continuum';

// SIZES
export const headerHeight = 50;

// BREAKPOINTS
export const xl = '1921px';

// COLORS
export const primary = '#006bb7';
export const alternative = '#e8f5ff';
export const basic = '#ffffff';
export const secondary = '#f1f1f1';
export const text = '#333333';
export const disabled = '#7e7e7e';
export const obscure = '#afafaf';
export const danger = '#e36969';
export const critical = '#dc3545';
export const borderColor = '#e2e2e2';
export const secondaryBorderColor = '#dfdfdf';
export const cardBg = '#e9e9e9';

// BORDERS
export const border = `1px solid ${borderColor}`;
export const tabBorder = '1px solid #707070';
export const sectionBorder = '4px solid #2c9ef5';

// GRADIENTS

export const nightAndDay = `linear-gradient(90deg, ${secondary}, ${secondary} 25%, transparent 25%, transparent 75%, ${secondary} 75%, ${secondary} 100%)`;

export const workingDay = `linear-gradient(
        90deg,
        ${secondary} 25%,
        transparent 25% 33.5%,
        black 33.5% 33.7%,
        transparent 33.7% 68.5%,
        black 68.5% 68.7%,
        transparent 68.7% 75%,
        ${secondary} 75% 100%
      )`;

export const verticalLines = `repeating-linear-gradient(
    90deg,
    transparent,
    transparent ${HOUR_WIDTH - GRID_LINE_WIDTH}px,
    ${secondaryBorderColor} ${HOUR_WIDTH - GRID_LINE_WIDTH * 2}px,
    ${secondaryBorderColor} ${HOUR_WIDTH}px
  )`;

export const horizontalLines = `repeating-linear-gradient(
    0deg,
    transparent,
    transparent ${ROW_HEIGHT - GRID_LINE_WIDTH}px,
    ${secondaryBorderColor} ${ROW_HEIGHT - GRID_LINE_WIDTH * 2}px,
    ${secondaryBorderColor} ${ROW_HEIGHT}px
  )`;

export const bgGrid = `${verticalLines}, ${horizontalLines}, ${nightAndDay}`;

// TRANSITION
export const transition = 'all 0.5s ease-out';

// Z-INDEX
export const zDrag = 2000;

// BOX SHADOWS
export const dangerShadow = `0px 0px 26px 0px ${critical}`;
export const dragBoxShadow = '0px 0px 26px 0px rgba(0,0,0,0.5)';
export const contextMenuShadow = '0 4px 5px 3px rgba(0, 0, 0, 0.2)';

// TASK
export const titleTaskColor = '#fff';
