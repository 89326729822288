import { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useSyntScrollListener = (flag, handler) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (flag) {
      document.addEventListener('syntscroll', handler);
    } else {
      document.removeEventListener('syntscroll', handler);
    }
    return () => document.removeEventListener('syntscroll', handler);
  }, [flag]);
};

export const useFetchData = (callback, reqArgument) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getData = async () => {
    let fetchedData = null;

    try {
      fetchedData = await callback(reqArgument);
      if (fetchedData.response) {
        setData(fetchedData.response);
        setIsLoading(false);
        return;
      }
      setData(fetchedData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (reqArgument) getData();
  }, [reqArgument]);

  return [isLoading, isError, data];
};

export const isQERP = url => {
  if (url.includes('qerp')) return true;
  if (url.includes('terp')) return false;
  return true;
};
