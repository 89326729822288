import styled from 'styled-components';
// import Router from 'next/router';
import * as Moment from 'moment';
import ReconnectingWebSocket from 'reconnecting-websocket';
import React, { useEffect, useRef, useState } from 'react';

import t from '../context/actionsTypes';
import API from '../api';
import Logo from './Logo';
import { HEADER_HEIGHT } from '../constants/continuum';
import { useRootContext } from '../context';
import { websocketError } from '../constants/errorsMsgs';
import { primary, basic } from '../constants/stylesConstants';
import {
  handleServerError,
  showErrorNotification,
} from '../utils/errorHandlers';
import { getWebsocketUrl } from '../utils/apiUrls';

export default function Header() {
  const { host } = window.location;
  const isProduction = host.startsWith('plan');

  let serverUrl = process.env.REACT_APP_SERVER_URL;

  if (isProduction) {
    const domain = host.slice(5);
    serverUrl = `https://${domain}`;
  }

  const { state, dispatch } = useRootContext();

  const actualStateCopy = useRef(null);

  const [isComponentReady, setComponentReady] = useState(false);

  const extractDate = info => Moment(info).format('YYYY-MM-DD');

  const updateDataForRelogedUser = async payload => {
    try {
      const data = await API.verifyUserAuth();

      if (data.user_id === payload.new_user_id) {
        const updatedInitialData = await API.fetchActivitiesAndEmployees({
          days: 21,
          offset: -7,
        });

        dispatch({
          type: t.SAVE_ACTIVITIES_AND_EMPLOYEES,
          payload: updatedInitialData,
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        document.location.replace(serverUrl);
      }
    }
  };

  const verifyUserAuthHandler = async () => {
    try {
      const data = await API.verifyUserAuth();

      if (data.user_id) return null;

      document.location.replace(serverUrl);
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        document.location.replace(serverUrl);
      }
    }

    return false;
  };

  const updateDaysSummaryHandler = async data => {
    const { start_point, end_point } = data;

    const visibleEmployees = actualStateCopy.current.allEmployees.filter(
      ({ visible }) => visible
    );

    const employeesFilteredBySearchQuery = actualStateCopy.current.employees;

    let employeeIds = [];

    if (visibleEmployees.length === employeesFilteredBySearchQuery.length)
      employeeIds = visibleEmployees.map(i => i.id);
    else employeeIds = employeesFilteredBySearchQuery.map(i => i.id);

    const dateRangeToUpdate = [start_point, end_point];

    if (data.old_start_point && data.old_end_point) {
      dateRangeToUpdate.push(data.old_start_point, data.old_end_point);
    }

    if (data.recurrence_start && data.recurrence_end) {
      dateRangeToUpdate.push(data.recurrence_start, data.recurrence_end);
    }

    dateRangeToUpdate.sort((date1, date2) => Moment(date1).diff(Moment(date2)));

    const startDate = dateRangeToUpdate[0];
    const endDate = dateRangeToUpdate[dateRangeToUpdate.length - 1];

    const startValue = extractDate(startDate);
    const endValue = extractDate(endDate);

    try {
      const updatedSummary = await API.updateDaysSummary(
        startValue,
        endValue,
        employeeIds
      );

      dispatch({
        type: t.UPDATE_DAYS_SUMMARY,
        payload: updatedSummary,
      });
    } catch (e) {
      handleServerError(e);
    }
  };

  const createWebSocket = WSUrl => {
    const rws = new ReconnectingWebSocket(WSUrl);

    const ping = () =>
      rws.send(JSON.stringify({ ping: new Date().toString() }));

    rws.onopen = () => {
      ping();
    };

    rws.onerror = () => showErrorNotification(websocketError);

    rws.onclose = () => {
      console.error('Connection closed');
    };

    rws.onmessage = event => {
      const data = JSON.parse(event.data);

      if (data.pong) {
        setTimeout(() => ping(), 3000);
      } else if (data.action) {
        const { action: type, payload } = data;

        const isDifferentPayloadDataTriggers = [
          t.DELETE_ACTIVITY,
          t.DELETE_RECURRING_ACTIVITY,
        ];

        const summaryUpdateTriggers = [
          t.INSERT_ACTIVITY,
          t.UPDATE_ACTIVITY,
          t.CREATE_RECURRING_ACTIVITY,
          t.UPDATE_RECURRING_ACTIVITY,
        ];

        if (type === 'LOGOUT') {
          verifyUserAuthHandler();
        } else if (type === 'RELOGIN') {
          updateDataForRelogedUser(payload);
        } else if (isDifferentPayloadDataTriggers.includes(type)) {
          const isSeveralItemsDeleted = payload.length > 0;

          if (isSeveralItemsDeleted) {
            const matchedActivities = state.activities.filter(activity =>
              payload.includes(activity.id)
            );

            if (matchedActivities.length > 0) {
              const startDates = matchedActivities.map(
                activity => activity.start_point || activity.recurrence_start
              );
              const earliestStartDate = startDates.sort((date1, date2) =>
                Moment(date1).diff(Moment(date2))
              )[0];

              const endDates = matchedActivities.map(
                activity => activity.end_point || activity.recurrence_end
              );
              const latestEndDate = endDates.sort((date1, date2) =>
                Moment(date1).diff(Moment(date2))
              )[endDates.length - 1];

              const datePeriod = {
                start_point: earliestStartDate,
                end_point: latestEndDate,
              };

              updateDaysSummaryHandler(datePeriod);
            }
          } else {
            const matchedActivity = state.activities.find(
              ({ id }) => id === payload
            );

            if (matchedActivity) {
              const datePeriod = {
                start_point:
                  matchedActivity.start_point ||
                  matchedActivity.recurrence_start,
                end_point:
                  matchedActivity.end_point || matchedActivity.recurrence_end,
              };

              updateDaysSummaryHandler(datePeriod);
            }
          }
        } else if (summaryUpdateTriggers.includes(type)) {
          updateDaysSummaryHandler(payload);
        }

        dispatch({
          type,
          payload,
        });
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('close', () => {
        rws.close(1000, 'User has closed window.');
      });
    }
  };

  const initWSConnection = () => {
    const WSUrl = getWebsocketUrl();

    if (WSUrl) return createWebSocket(WSUrl);

    return setTimeout(() => {
      initWSConnection();
    }, 2000);
  };

  useEffect(() => {
    if (!isComponentReady && state.allEmployees.length > 0) {
      setComponentReady(true);
    }

    actualStateCopy.current = state;
  }, [state]);

  useEffect(() => {
    if (isComponentReady && state.allEmployees.length > 0) {
      initWSConnection();
    } else if (isComponentReady && state.allEmployees.length === 0) {
      setTimeout(() => {
        initWSConnection();
      }, 10000);
    }
  }, [isComponentReady]);

  return (
    <HeaderWrapper>
      <Logo />
      <Version>v1.6.3</Version>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  padding: 0 24px;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: ${primary};
`;

const Version = styled.div`
  color: ${basic};
  align-self: flex-end;
  margin-bottom: 8px;
`;
