import { createGlobalStyle } from 'styled-components';
import reset from './reset';
import { text, critical, dangerShadow } from '../constants/stylesConstants';

export default createGlobalStyle`

  ${reset}

  * {
    box-sizing: border-box;
  }

  body, html {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    color: ${text};
    --antd-wave-shadow-color: black;
  }

  /* Interrupting default ant styles for TimePicker */

  .ant-calendar-input  {
    color: ${text};
  }

  .anticon-close-circle {
    display: none;
  }

  .ant-time-picker-panel-select {
    display: flex;
    justify-content: center;
  }
  
  .ant-time-picker-panel-select ul {
    padding: 0;
  }

  .overlap {
    background-color: ${critical} !important;
    z-index: 1001;
    pointer-events: none;
    box-shadow: ${dangerShadow};
  }

  .conflict {
    border: 2px dashed #500;
  }
`;
